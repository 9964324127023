import { useLazyQuery, useMutation } from '@apollo/client'
import { TC_ACKNOWLEDGEMENT } from "../graphql/mutations"
import { TERMS_AND_CONDITIONS } from "../graphql/queries"
import { toast } from 'react-toastify'
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { setUserAction } from "../redux/actions/userActions"
import { datadogLogs } from '@datadog/browser-logs';

const 
useTermsAndConditions = () => {
  const [, {refetch: fetchTermsAndConditions}] = useLazyQuery(TERMS_AND_CONDITIONS)
  const [tcAcknowledgement] = useMutation(TC_ACKNOWLEDGEMENT)
  const dispatch: Dispatch<any> = useDispatch()


  const getTermsAndConditions = (setTermsAndConditionsData: React.Dispatch<React.SetStateAction<TermsAndConditionsData>>) => {
    datadogLogs.logger.info("Fetching terms and conditions...");
    
    fetchTermsAndConditions().then((response) => {
      datadogLogs.logger.info("Terms and conditions fetched successfully.");
      console.log(response.data)
      setTermsAndConditionsData(response.data)
    })
  }

  const createTcAcknowledgement = (acknowledgement: boolean) => {
    datadogLogs.logger.info("Submitting user acknowledgment of terms and conditions...");
    
    tcAcknowledgement({
      variables: {
        acknowledgement: acknowledgement
      }
    }).then((response) => {
      let user: IUser = {
        id: response.data.tcAcknowledgement.id,
        authorized: true,
        firstName: response.data.tcAcknowledgement.firstName,
        lastName: response.data.tcAcknowledgement.lastName,
        email: response.data.tcAcknowledgement.email,
        availabilityStatus: response.data.tcAcknowledgement.availabilityStatus,
        tcAcknowledgementPending: response.data.tcAcknowledgement.tcAcknowledgementPending,
        smAcknowledgementPending: response.data.tcAcknowledgement.smAcknowledgementPending
      }
      dispatch(setUserAction(user))
      datadogLogs.logger.info("User acknowledgment of terms and conditions submitted successfully.");
      toast.info("Terms and Conditions has been acknowledged.")
    })
  }

  return { getTermsAndConditions, createTcAcknowledgement }
}

export default useTermsAndConditions
