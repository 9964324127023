import { useEffect, useState } from 'react'
import useAuthentication from '../../../customHooks/useAuthentication'
import Degrees from './Degrees'
import Licenses from './Licensure'
import Projects from './Projects'
import Resume from './Resume'
import AboutMe from './AboutMe'
import PageHeading from '../../shared/PageHeading'
import RTabs from '../../shared/ComponentLibrary/RTabs'
import TabPanel from '../../shared/ComponentLibrary/TabPanel'
import BasicInfromation from './BasicInformation'
import { useLocation } from 'react-router-dom'
import { Dispatch } from "redux"
import { useDispatch } from "react-redux"
import { setDirtyForm } from '../../../redux/actions/profileActions'
import { useSelector } from "react-redux"
import BreadcrumbsPath from '../../shared/BreadcrumbsPath'

const Profile = () => {
  const dispatch: Dispatch<any> = useDispatch()
  const profileStatus: UserProfileStatus = useSelector((state: ProfileState) => state.profile)
  const location = useLocation()
  const [activeTab, setActiveTab] = useState<string>(location.state?.from || 'information')
  const [profileData, setProfileData] = useState<UserProfile>()
  const { getUserInformation } = useAuthentication()
  const [dirtyForm, setDirtyFormFlag] = useState(false)

  useEffect(() => { dispatch(setDirtyForm({...profileStatus, dirtyForm: dirtyForm})) }, [dirtyForm])
  useEffect(() => getUserInformation(setProfileData), [])

  return (
    <div>
      <BreadcrumbsPath />
      <PageHeading
        icon='genderNeutralUser'
        title='Your Profile'
        divider
      />
      <RTabs activeTab={activeTab} setActiveTab={setActiveTab} dirtyForm={dirtyForm}/>
      { profileData &&
        <div>
          <TabPanel activeTab={activeTab} index={'information'}>
            <BasicInfromation profileData={profileData} setProfileData={setProfileData} setDirtyForm={setDirtyFormFlag}/>
          </TabPanel>
          <TabPanel activeTab={activeTab} index={'education'}>
            <Degrees profileData={profileData} setProfileData={setProfileData} setDirtyForm={setDirtyFormFlag} />
          </TabPanel>
          <TabPanel activeTab={activeTab} index={'about'}>
            <AboutMe profileData={profileData} setProfileData={setProfileData} setDirtyForm={setDirtyFormFlag} />
          </TabPanel>
          <TabPanel activeTab={activeTab} index={'resume'}>
            <Resume profileData={profileData} setProfileData={setProfileData} setDirtyForm={setDirtyFormFlag} />
          </TabPanel>
          <TabPanel activeTab={activeTab} index={'experience'}>
            <Projects profileData={profileData} setProfileData={setProfileData} setDirtyForm={setDirtyFormFlag} />
          </TabPanel>
          <TabPanel activeTab={activeTab} index={'licensure'}>
            <Licenses profileData={profileData} setProfileData={setProfileData} setDirtyForm={setDirtyFormFlag} />
          </TabPanel>
        </div>
      }

    </div>
  )
}

export default Profile
