import { useLazyQuery, useMutation } from '@apollo/client'
import { INBOX_ACKNOWLEDGEMENT } from "../graphql/mutations"
import { INBOX_MESSAGES } from "../graphql/queries"
import { toast } from 'react-toastify'
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { datadogLogs } from '@datadog/browser-logs';
import { setInboxMessagesAction } from '../redux/actions/inboxAction'

const useInboxMessages = () => {
  const [fetchInboxMessages, { data, refetch }] = useLazyQuery(INBOX_MESSAGES)
  const [inboxAcknowledgement] = useMutation(INBOX_ACKNOWLEDGEMENT)
  const dispatch: Dispatch<any> = useDispatch()


  const getInboxMessages = (userId: string | undefined) => {
    datadogLogs.logger.info("Fetching inbox messages (if any)...");
    fetchInboxMessages({ variables: { userId } }).then((response) => {
      if (response.data) {
        dispatch(setInboxMessagesAction(response.data.inboxMessages))
        datadogLogs.logger.info("Inbox messages fetched successfully.");
      } else {
        datadogLogs.logger.info("No inbox messages found.");
      }
    })
  }

  const createInboxAcknowledgement = (acknowledgement: boolean, userId: number, systemMessageId: number, priority: string = 'low') => {
    datadogLogs.logger.info("Submitting user acknowledgment of system messages...");

    inboxAcknowledgement({
      variables: {
        acknowledgement: acknowledgement,
        userId: userId,
        systemMessageId: systemMessageId
      }
    }).then((response) => {
      if (response.data) {
        refetch({ userId: userId }).then((response) => {
          dispatch(setInboxMessagesAction(response.data.inboxMessages))
        })
        datadogLogs.logger.info("User acknowledgment of system messages submitted successfully.");
        if (priority === 'high') {
          toast.info("Inbox Message has been acknowledged.")
        }
      }
    })
  }

  return { getInboxMessages, createInboxAcknowledgement, refetch }
}

export default useInboxMessages
