import { useState, useEffect } from 'react'
import { useSelector } from "react-redux"
import EligibilityStatus from './EligibilityStatus'
import AvailabilityDashboardStatus from './AvailabilityDashboardStatus'
import classes from './Dashboard.module.scss'
import PageHeading from './../../shared/PageHeading'
import Icon from '../../../Icon'
import { Link } from 'react-router-dom'
import useProfile from '../../../customHooks/useProfile'


const Dashboard = () => {
  const profileStatus: UserProfileStatus = useSelector((state: ProfileState) => state.profile)
  const [profileStatusData, setProfileStatusData] = useState<UserProfileStatus>(profileStatus)
  const { getUserProfileStatus, accountInfo } = useProfile()
  const user: IUser = useSelector((state: UserState) => state.user)
  const [error, setError] = useState('')

  const isEligible = () => !Object.values(profileStatusData).includes(false)
  
  function calculateProfileProgress(profileData: UserProfileStatus){
    const entries = Object.entries(profileData).filter((kvp) => kvp[0] !== 'realAssessment' && kvp[0] !== 'assessmentTakenOn');
    const denom = entries.length > 0 ? entries.length : 1;
    const numer = entries.filter((kvp) => kvp[1] === true).length;
    return numer / denom;
  }
  
  useEffect(() => getUserProfileStatus(setProfileStatusData), [])
  useEffect(() => console.log(error), [error])
  
  return (
    <div>
      <div>
        <div className={classes.account_button}>
          <Link className="btn" to='/account'>
            <Icon name='gear' size='36' />
          </Link>
        </div>
        <PageHeading
          title='Dashboard'
          description='Manage your account, update your profile details including licensure, education, and experience. Head to the assessment area to show off your document review skills.'
          divider
        />
      </div>
      <EligibilityStatus status={isEligible() ? 'eligible' : 'ineligible'} profileProgress={calculateProfileProgress(profileStatusData)} assessmentCompleted={profileStatusData.realAssessment === true} assessmentTakenOn={profileStatus.assessmentTakenOn ?? ''} />
      <AvailabilityDashboardStatus availability={user.availabilityStatus ?? false} tempUserDontKeep={{firstName: user.firstName, lastName: user.lastName, email: user.email}} updateAccount={accountInfo} setError={setError} isEligible={isEligible()} />
    </div>
  )
}

export default Dashboard
