import React from "react";
import classes from "./Inbox.module.scss";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination = ({ currentPage, totalPages, onPageChange }: PaginationProps) => {
  return (
    <div className={classes.pagination}>
      {/* Previous Button */}
      <span
        className={`${classes.pagination__prev} ${currentPage === 1 ? classes.disabled : ""}`}
        onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
      >
        ← Previous
      </span>

      {/* Page Numbers */}
      <div className={classes.pagination__numbers}>
        {[...Array(totalPages)].map((_, index) => {
          const page = index + 1;
          return (
            <span
              key={page}
              className={`${classes.pagination__number} ${currentPage === page ? classes.active : ""}`}
              onClick={() => onPageChange(page)}
            >
              {page}
            </span>
          );
        })}
      </div>

      {/* Next Button */}
      <span
        className={`${classes.pagination__next} ${currentPage === totalPages ? classes.disabled : ""}`}
        onClick={() => currentPage < totalPages && onPageChange(currentPage + 1)}
      >
        Next →
      </span>
    </div>
  );
};

export default Pagination;
