// User
export const SET_USER = "SET_USER"
export const LOGOUT_USER = "LOGOUT_USER"

// Profile
export const USER_PROFILE_PROGRESS = "USER_PROFILE_PROGRESS"
export const UPDATE_ACTIVE_TAB = "UPDATE_ACTIVE_TAB"
export const RESET_ACTIVE_TAB = "RESET_ACTIVE_TAB"
export const SET_DIRTY_FORM = "SET_DIRTY_FORM"

// Inbox
export const SET_INBOX_MESSAGES = "SET_INBOX_MESSAGES"
