import { combineReducers } from "redux";
import userReducer from "./userReducer"
import profileReducer from "./profileReducer"
import inboxReducer from "./inboxReducer";

const RootReducer = combineReducers({
  user: userReducer, //user
  profile: profileReducer,
  inboxMessages: inboxReducer,
});

const rootReducer = (state: any, action: any) => RootReducer(state, action)

export default rootReducer
