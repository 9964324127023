import * as actionTypes from "../actionTypes"

const defaultState = {
  inboxMessages: [],
}

const inboxReducer = (state = defaultState, action: InboxMessagesAction) => {
  switch (action.type) {
    case actionTypes.SET_INBOX_MESSAGES:
      return { inboxMessages: action.inboxMessages }
    case actionTypes.LOGOUT_USER:
      return {
        inboxMessages: [],
      }
    default:
      return state
  }
}

export default inboxReducer
