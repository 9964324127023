import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

type RBooleanToggleDecalProps = {
  checkedText?: string
  uncheckedText?: string
  checkedIcon?: React.ReactElement
  uncheckedIcon?: React.ReactElement
}

type RBooleanToggleProps = {
  checked?: boolean
  defaultChecked?: boolean
  onChangeMethod?: (event: React.ChangeEvent) => void
  hidden?: boolean
  disabled?: boolean
  decals?: RBooleanToggleDecalProps
  privacy?: 'allow' | 'mask' | 'hidden' | 'mask-user-input'
}

const DefaultSwitchThumb = styled('div')({
  backgroundColor: 'white',
  width: 31,
  height: 31,
  borderRadius: '50%',
  padding: 9,
  content: "''"
});

const SwitchThumbJoiner = styled('div')({
  position: 'relative'
});

const SwitchThumbIconHolder = styled('div')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  marginInline: "auto",
  width: "fit-content"
});

const SwitchHolder = styled('div')({
  position: 'relative'
});

const SwitchLabel = styled(Typography)({
  userSelect: 'none',
  pointerEvents: 'none',
  zIndex: 1,
  fontWeight: 600,
  color: 'white',
  transform: 'translateY(2px)'
});

const SwitchLabelHolder = styled('div')({
  display: "flex",
  position: "absolute",
  height: "100%",
  width: "100%",
  top: 0,
  alignItems: "center"
});

const RBooleanToggle = ({checked, defaultChecked, onChangeMethod, hidden, disabled, decals, privacy="allow"}: RBooleanToggleProps) => {
  
  function getThumb(icon?: React.ReactElement){
    if (icon){
      return <SwitchThumbJoiner><SwitchThumbIconHolder>{icon}</SwitchThumbIconHolder><DefaultSwitchThumb></DefaultSwitchThumb></SwitchThumbJoiner>
    }
    return <SwitchThumbJoiner><DefaultSwitchThumb></DefaultSwitchThumb></SwitchThumbJoiner>
  }
  
  function getLabelWidth(decals?: RBooleanToggleDecalProps){
    if (!decals || (!decals!.checkedText && !decals!.uncheckedText)){
      return undefined;
    }
    let textLength = 0;
    if (decals!.checkedText && !decals!.uncheckedText){
      textLength = decals!.checkedText!.length;
    } else if (decals!.uncheckedText && !decals!.checkedText){
      textLength = decals!.uncheckedText!.length;
    } else {
      textLength = Math.max(decals!.checkedText!.length, decals!.uncheckedText!.length);
    }
    
    return textLength * 16;
  }
  
  let CustomBooleanToggle = styled(Switch)<SwitchProps>({
    '&': {
      
        height: "40px",
        boxSizing: "content-box",
        alignItems: "center"
      },
    '& .MuiSwitch-track': {
      borderRadius: "2vw",
      opacity: 1,
      backgroundColor: "#DF1C41"
    },
    '& .MuiSwitch-switchBase': {
      transform: "translateX(" + 7 + "px)",
      top: 0,
      bottom: 0,
      '&.Mui-checked': {
        transform: "translateX(" + (getLabelWidth(decals)! - 31 - 4) + "px)",
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: "#2CB6D1"
        },
        '&:hover':{
          backgroundColor: "#2CB6D166",
          width: 48,
          height: 48,
          marginTop: 'auto',
          marginBottom: 'auto'
        }
      },
      '&.Mui-disabled +  .MuiSwitch-track': {
        opacity: 0.5
      },
      '& .MuiSwitch-input': {
        zIndex: 2
      },
      '&:hover':{
        backgroundColor: "#DF1C4166",
        width: 48,
        height: 48,
        marginTop: 'auto',
        marginBottom: 'auto'
      }
      
    }
    
  })

  return(
    <SwitchHolder>
      {checked && (<SwitchLabelHolder sx={{justifyContent: "center"}}><SwitchLabel>{decals?.checkedText}</SwitchLabel></SwitchLabelHolder>)}
      {!checked && (<SwitchLabelHolder sx={{justifyContent: "center"}}><SwitchLabel>{decals?.uncheckedText}</SwitchLabel></SwitchLabelHolder>)}
      <CustomBooleanToggle checked={checked} defaultChecked={defaultChecked} onChange={onChangeMethod} hidden={hidden} disabled={disabled} icon={getThumb(decals?.uncheckedIcon)} checkedIcon={getThumb(decals?.checkedIcon)}
              sx={{width: getLabelWidth(decals)}}
              className= {`${privacy === undefined ? '' : ('dd-privacy-' + privacy)}`}
      />
      
    </SwitchHolder>
  )
}


export default RBooleanToggle
