import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Icon from '../../../Icon';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TimelineDot from '@mui/lab/TimelineDot'
import { TimelineDotProps } from '@mui/lab/TimelineDot'
import CircularProgress, { circularProgressClasses, CircularProgressProps } from '@mui/material/CircularProgress'
import classes from './Dashboard.module.scss'
import { SxProps, Theme, useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'


enum AlertTitles {
  "ineligible" = "Not Eligible",
  "eligible" = "Eligible",
}

enum AlertMessage {
  "ineligible" = "To become eligible for document review assignments, please complete the missing items below:",
  "eligible" = "Congratulations! You are currently eligible for document review assignments.",
}

enum Colors {
  "ineligible" = "#FC9C9C",
  "eligible" = "#6BE3A2",
}

enum IconNames {
  "ineligible" = 'highPriority',
  "eligible" = "checkmarkWhite",
}

type EligibilityStatusProps = {
  status: 'eligible' | 'ineligible',
  profileProgress: number,
  assessmentCompleted: boolean
  assessmentTakenOn: string
}

function CircularProgressWithLabel(props: CircularProgressProps & { value: number, textsx?: SxProps<Theme>, undersx?: SxProps<Theme> }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <Box sx={{position: 'relative', width: `${props.size}px`, height: `${props.size}px`}}>
        <CircularProgress variant="determinate" {...props} value={100} sx={{...props.undersx, position: 'absolute'}} />
        <CircularProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography component="div" sx={props.textsx} >
          {`${props.value}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const EligibilityStatus = ({ status, profileProgress, assessmentCompleted, assessmentTakenOn }:EligibilityStatusProps) => {
  
  const theme = useTheme();
  
  const CustomBox = styled(Box)({
    borderRadius: 10,
    fontSize: 14,
    fontWeight: 400,
    padding: '10px 15px',
    border: '1px solid',
    fontFamily: 'Open Sans',
    alignItems: 'center',
    flexGrow: 1
  })
  
  const CustomOuterBox = styled(CustomBox)({
    backgroundColor: Colors[status],
    borderColor: Colors[status],
    color: '#FFFFFF',
    padding: '15px 15px',
  })
  
  const CustomInnerBox = styled(CustomBox)({
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
    color: '#393939',
    height: '100%'
  })
  
  const CustomInnerBoxShadow = styled(CustomInnerBox)({
    boxShadow: '0px 3px 6px #00000029'
  })
  
  const CustomBoxTitle = styled(Typography)({
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 0,
    marginTop: -2
  })

  const IsolatedTimelineDot = (props: TimelineDotProps) => (
    <TimelineDot 
      sx={{
        color: "white",
        backgroundColor: "#2CB6D1",
        width: 30,
        height: 30,
        alignItems: "center",
        justifyContent: "center",
        margin: 0}} 
      className={classes.timeline_dot} {...props}/>
  );

  const formattedDate = new Date(assessmentTakenOn || '').toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });

  const formattedTime = new Date(assessmentTakenOn || '').toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const profileProgressValue = Math.round(profileProgress * 100)
  return (
  <>
    <CustomOuterBox sx={{mb: 2}}>
      <Grid container spacing={1.5}>
        <Grid item xs="auto">
          <Icon name={IconNames[status]} size='28' />
        </Grid>
        <Grid item xs>
          <CustomInnerBox>
            <CustomBoxTitle>Eligibility Status: { AlertTitles[status] }</CustomBoxTitle>
            {AlertMessage[status]}
          </CustomInnerBox>
        </Grid>
      </Grid>
    </CustomOuterBox>
    <Grid container spacing={2} mb={2}>
      <Grid item xs={12} md={6}>
        <Link to='/profile'>
          <CustomInnerBoxShadow>
            <IsolatedTimelineDot>
              <span>1</span>
            </IsolatedTimelineDot>
            <Stack direction="row" spacing={1} sx={{alignItems: 'center', ml:3}}>
              <Icon name='genderNeutralUser' size='26' color='black' />
              <CustomBoxTitle>Profile</CustomBoxTitle>
            </Stack>
            <Stack direction="row" sx={{justifyContent: 'center'}}>
              <CircularProgressWithLabel value={profileProgressValue} size={80} sx={{color: profileProgressValue < 100 ? "#DF1C41" : "#2CB6D1", [`& .${circularProgressClasses.circle}`]: {strokeLinecap: 'round' }}} textsx={{fontSize: 20, fontFamily: 'Open Sans', color: '#393939', fontWeight: 600}} undersx={{color: "#EAF0F2"}} />
            </Stack>
          </CustomInnerBoxShadow>
        </Link>
      </Grid>
      <Grid item xs={12} md={6}>
        <Link to='/assessment'>
          <CustomInnerBoxShadow>
            <IsolatedTimelineDot>
              <span>2</span>
            </IsolatedTimelineDot>
            <Box sx={{display: 'inline-grid', gridTemplateAreas: '"eligibility_status_assessment_icon eligibility_status_assessment_title" "eligibility_status_assessment_blank eligibility_status_assessment_status"', columnGap: theme.spacing(1), alignItems: 'center', ml: 3}}>
              <div className='eligibility_status_assessment_icon'><Icon name='testResults' size='26' color='black' /></div>
              <div className='eligibility_status_assessment_title'><CustomBoxTitle sx={{lineHeight: 1, mt: 0}}>Assessment</CustomBoxTitle></div>
              <div className='eligibility_status_assessment_blank'/>
              <div className='eligibility_status_assessment_status'>
                <CustomBoxTitle sx={{ color: assessmentCompleted ? "#2CB6D1" : "#DF1C41" }}>{assessmentCompleted ? `Assessment completed on ${formattedDate}, ${formattedTime}` : "No assessment taken"}</CustomBoxTitle>
              </div>
            </Box>
            <Stack direction="row" sx={{justifyContent: 'center'}}>
              
            </Stack>
          </CustomInnerBoxShadow>
        </Link>
      </Grid>
    </Grid>
  </>
  )
}

export default EligibilityStatus
