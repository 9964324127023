import { useSelector } from "react-redux"
import TermsAndConditions from "./TermsAndConditions"
import { useEffect } from "react"
import HighPriorityMessage from "./HighPriorityMessage"

type SystemMessagesProps = {
  tcAcknowledgementPending?: boolean
  smAcknowledgementPending?: boolean
  highPriorityMessages?: InboxMessages
}

const SystemMessages = ({ tcAcknowledgementPending, smAcknowledgementPending, highPriorityMessages = [] }: SystemMessagesProps) => {
  const user: IUser = useSelector((state: UserState) => state.user)

  useEffect(() => {
    // re-render whenever there is a change in user and highPriorityMessages
  }, [highPriorityMessages, user])

  return (
    <div>
      {/* Always render TermsAndConditions first if tcAcknowledgementPending is true */}
      {tcAcknowledgementPending && <TermsAndConditions />}

      {/* After TermsAndConditions, render all high-priority messages */}
      {!tcAcknowledgementPending && highPriorityMessages.length > 0 && (
        <HighPriorityMessage key={highPriorityMessages[0].id} message={highPriorityMessages[0]} />
      )}
    </div>
  )
}

export default SystemMessages
