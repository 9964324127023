import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import classes from '../../shared/ComponentLibrary/RTabs/RTabs.module.scss'

type TabsType = {
  [key: string]: { value: string, label: string }
}

const TabsData: TabsType = Object.freeze({
  'all': { value: 'all', label: 'All' },
  'read': { value: 'read', label: 'Read' },
  'unread': { value: 'unread', label: 'Unread' },
})

type InboxTabsProps = {
  activeTab: string
  setActiveTab: (tab: string) => void
}

const InboxTabs = ({ activeTab, setActiveTab }: InboxTabsProps) => {
  const handleChange = (event: React.SyntheticEvent, tab: string) => {
    setActiveTab(tab)
  }

  return (
    <div className={classes.tabs_container}>
      <Tabs
        classes={{
          flexContainer: classes.tabs,
          scroller: classes.scroller,
          indicator: classes.indicator
        }}
        value={activeTab}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons={false}
      >
        {Object.keys(TabsData).map((key) => (
          <Tab
            key={key}
            classes={{
              textColorPrimary: classes.tab,
              selected: classes.active_tab
            }}
            value={TabsData[key].value}
            label={TabsData[key].label}
            disableTouchRipple
          />
        ))}
      </Tabs>
    </div>
  )
}

export default InboxTabs
