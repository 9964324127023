import React, { useState } from 'react';
import Icon from '../../../Icon';
import classes from './Inbox.module.scss'
import { useNavigate } from 'react-router-dom';

type RowProps = {
  message: InboxMessage;
};

const Row = ({ message }: RowProps) => {
  const { title, priority, read, publishedAt } = message;

  const publishedDate = new Date(publishedAt);

  const formattedDate = publishedDate.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });

  const formattedTime = publishedDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/inbox/${message.id}`);
  };


  return (
    <>
      <div className={`${classes.message} ${read ? "" : classes["message__unread"]}`} onClick={handleClick}>
        <div className={classes.message__left}>
          {priority === "high" && <Icon name='priority' />}
          <span className={classes.message__title}>{title}</span>
        </div>

        <div className={classes.flex__col}>
          <span className={`${classes.message__time} ${classes.font__semibold}`}>{formattedDate}</span>
          <span className={`${classes.message__time} ${classes.font__semibold}`}>{formattedTime}</span>
        </div>
      </div>
    </>
  );
};

export default Row;
