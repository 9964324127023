import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import Authentication from '../Authentication'
import useAuthentication from '../../customHooks/useAuthentication'
import { toast } from 'react-toastify'
import { checkSession } from '../../helpers/session'
import SystemMessages from '../Pages/SystemMessages'
import TermsAndConditions from '../Pages/SystemMessages/TermsAndConditions'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum';
import useInboxMessages from '../../customHooks/useInbox'
import { setInboxMessagesAction } from '../../redux/actions/inboxAction'
import { Dispatch } from 'redux'

type PrivateRouteProps = {
  path: string,
  Component: React.FunctionComponent
}

const PrivateRoute = ({ path, Component }: PrivateRouteProps) => {
  const user: IUser = useSelector((state: UserState) => state.user)
  const inboxMessages: InboxMessages = useSelector((state: InboxMessagesState) => state.inboxMessages.inboxMessages)
  const [highPriorityMessages, setHighPriorityMessages] = useState<InboxMessage[]>([]);
  const credentials = JSON.parse(localStorage.getItem('credentials')!)
  const location = useLocation()
  const { signOut } = useAuthentication()
  const { getInboxMessages, refetch } = useInboxMessages()
  const dispatch: Dispatch<any> = useDispatch()

  useEffect(() => {
    if (checkSession()) {
      signOut()
      toast.info("Session has been expired, Sign in again.")
    }
  }, [location])

  useEffect(() => {
    if (user?.id) {
      getInboxMessages(user.id)
      setHighPriorityMessages(inboxMessages
        .filter((msg) => msg.read == false && msg.priority == 'high')
        .sort((a: InboxMessage, b: InboxMessage) => new Date(a.publishedAt).getTime() - new Date(b.publishedAt).getTime())
      )
    }
  }, [user]);

  // refetch logic with 5 sec interval
  useEffect(() => {
    const interval = setInterval(() => {
      if (user?.id) {
        refetch({ userId: user.id }).then((response) => {
          if (response.data) {
            dispatch(setInboxMessagesAction(response.data.inboxMessages));
            setHighPriorityMessages(response.data.inboxMessages
              .filter((msg: { read: boolean; priority: string }) => msg.read == false && msg.priority == 'high')
              .sort((a: InboxMessage, b: InboxMessage) => new Date(a.publishedAt).getTime() - new Date(b.publishedAt).getTime())
            )
          }
        });
      }
    }, 5000);
  
    return () => clearInterval(interval);
  }, [user, dispatch, refetch]);
  

  if (user.authorized) {
    datadogLogs.setUser({ id: credentials.uid, email: user.email });
    datadogRum.setUser({ id: credentials.uid, email: user.email });
    return (user.tcAcknowledgementPending || highPriorityMessages.length) ? <SystemMessages tcAcknowledgementPending={user.tcAcknowledgementPending} smAcknowledgementPending={user.smAcknowledgementPending} highPriorityMessages={highPriorityMessages} /> : <Component />
  } else {
    return <Authentication path={path} />
  }
}

export default PrivateRoute
