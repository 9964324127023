import { useState, useEffect } from 'react'
import classes from './Sidebar.module.scss'
import Drawer from '@mui/material/Drawer'
import { useLocation } from 'react-router-dom'
import { useSelector } from "react-redux"
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import Icon from '../../../Icon'
import Tab from './Tab'
import useAuthentication from '../../../customHooks/useAuthentication'
import { updateActiveTab } from '../../../redux/actions/profileActions'
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"


type RoutesType = {
  [key: string]: RouteType
}

const SideDrawer = () => {
  let location = useLocation()
  const dispatch: Dispatch<any> = useDispatch()
  const { signOut } = useAuthentication()
  const [selectedTab, setSelectedTab] = useState<string>(location.pathname)
  const [open, setOpen] = useState(false)
  const [activeSubMenu, setActiveSubMenu] = useState(location.state?.from)
  const profileStatus: UserProfileStatus = useSelector((state: ProfileState) => state.profile)

  const Routes: RoutesType = Object.freeze({
    'dashboard': { name: 'Dashboard', path: '/', icon: 'homePage' },
    'inbox': { name: 'Inbox', path: '/inbox', icon: 'inbox' },
    'profile': {
      name: 'Profile',
      path: '/profile',
      icon: 'genderNeutralUser',
      subRoutes: [
        { name: 'Basic Information', isCompleted: profileStatus.basicInformation, path: 'information' },
        { name: 'Education', isCompleted: profileStatus.education, path: 'education' },
        { name: 'About Me', isCompleted: profileStatus.biography, path: 'about' },
        { name: 'Resume', isCompleted: profileStatus.resume, path: 'resume' },
        { name: 'Licensure', isCompleted: profileStatus.licensure, path: 'licensure' },
        { name: 'Review Experience', isCompleted: profileStatus.documentReviewExperience, path: 'experience' }
      ],
    },
    'assessment': { name: 'Assessment', path: '/assessment', icon: 'testResults' },
    'account': { name: 'Account', path: '/account', icon: 'gear' },
  })

  useEffect(() => {
    setSelectedTab(location.pathname)
    location.state?.from && setActiveSubMenu(location.state?.from)
  }, [location.pathname])

  useEffect(() => { dispatch(updateActiveTab({...profileStatus, activeTab: activeSubMenu})) }, [selectedTab, activeSubMenu])
  useEffect(() => setActiveSubMenu(profileStatus.activeTab), [profileStatus.activeTab])

  const toggleDrawer = () => {
    setOpen((current) => !current)
  }

  return (
    <div className={classes.side_drawer}>
      <div
        className={classes.header}
        onClick={toggleDrawer}
      >
        <Icon
          name={ open ? 'deleteSign' : 'menuRounded' }
          color='white'
          size='26'
        />
      </div>

      <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            width: '100%',
          },
        }}
        classes={{paperAnchorRight: classes.drawer}}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <div className={classes.navigations}>
          <List>
            {Object.keys(Routes).map((key) => (
              <ListItem key={key} disablePadding >
                <ListItemButton classes={{root: classes.list_item}} disableTouchRipple >
                  <Tab
                    route={Routes[key]}
                    isActiveTab={Routes[key].path === selectedTab }
                    activeSubMenu={activeSubMenu}
                    setActiveSubMenu={setActiveSubMenu}
                    activateParentTab={() => {
                      setSelectedTab(Routes[key].path)
                      toggleDrawer()
                      setActiveSubMenu('information')
                    }}
                    arrowColor='white'
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </div>
        <div className={classes.signout_button}>
          <button className={`btn ${classes.button}`} onClick={signOut}>
            <Icon name='logoutRoundedLeft' size='18' />
            <span>Sign Out</span>
          </button>
        </div>

        <div className={classes.version_number}>
          <span>{`v${process.env.REACT_APP_VERSION_NO}`}</span>
        </div>
      </Drawer>
    </div>
  )
}

export default SideDrawer