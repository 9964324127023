import React, { useState } from 'react';
import Row from './Row';
import Pagination from './Pagination';

type InboxGridProps = {
  inboxMessages: InboxMessages;
};

const InboxGrid = ({ inboxMessages }: InboxGridProps) => {
  const ITEMS_PER_PAGE = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(inboxMessages.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const displayedMessages = inboxMessages.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  return (

    <div className='py-4' >
      {displayedMessages.length === 0 && (
        <div className='text-center text-gray-500'>There are no messages.</div>
      )}
      {displayedMessages.map((message, index) => (
        <Row key={index} message={message} />
      ))}
      <div>
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
    </div>
    </div >
  );
};

export default InboxGrid;
