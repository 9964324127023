import { Breadcrumbs, Link } from "@mui/material";
import { useLocation, Link as RouterLink } from "react-router-dom";
import Routes from "../../../helpers/routes";
import classes from "./BreadcrumbsPath.module.scss"

const BreadcrumbsPath = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);

  let fullPath = "";
  const breadcrumbs = pathSegments
    .slice(0, -1) // Remove the last segment
    .map((segment) => {
      fullPath += `/${segment}`;

      // Check if it's a main route
      const mainRoute = Object.values(Routes).find((route) => route.path === `/${segment}`);
      if (mainRoute) return { path: fullPath, label: mainRoute.name };

      return { path: fullPath, label: segment };
    });

  return (
    <Breadcrumbs separator=">" aria-label="breadcrumb" className={classes.container}>
      <Link component={RouterLink} to={Routes.dashboard.path} className={classes.title} underline="hover">
        {Routes.dashboard.name}
      </Link>
      {breadcrumbs.map((crumb, index) => (
        <Link
          key={index}
          component={RouterLink}
          to={crumb.path}
          className={classes.title}
          underline="hover"
        >
          {crumb.label}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default BreadcrumbsPath;
