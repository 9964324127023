import React, { useEffect } from 'react';
import PageHeading from "../../shared/PageHeading"
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { HtmlSafeElement } from '../../shared/HtmlSafeElement';
import classes from './Inbox.module.scss'
import useInboxMessages from '../../../customHooks/useInbox';
import BreadcrumbsPath from '../../shared/BreadcrumbsPath';

const Message = () => {

  const inboxMessages: InboxMessages = useSelector((state: InboxMessagesState) => state.inboxMessages.inboxMessages)
  const user: IUser = useSelector((state: UserState) => state.user)
  const { id } = useParams();
  const message = inboxMessages.find((msg) => msg.id === id);
  const publishedDate = new Date(message?.publishedAt || '');
  const { createInboxAcknowledgement } = useInboxMessages()

  useEffect(() => {
    if (message && !message.read) {
      createInboxAcknowledgement(true, Number(user.id), Number(message.id));
    }
  }, []);

  const formattedDate = publishedDate.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });

  const formattedTime = publishedDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return (
    <div>
      <BreadcrumbsPath />
      <PageHeading
        icon='inbox'
        title='Message'
        divider
      />
      <div className={classes.flex__row}>
        <span className={classes.msg__title}>{message?.title}</span>
        <span className={classes.font__semibold}>{formattedDate}, {formattedTime}</span>
      </div>
      <div className={classes.msg__content}>
        <HtmlSafeElement element="span" content={message?.content} />
      </div>
    </div>
  );
};

export default Message;
