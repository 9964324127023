import { useMutation, useLazyQuery } from '@apollo/client'
import { UPDATE_ACCOUNT_INFO,
         UPDATE_USER_PROFILE,
         UPDATE_DEGREE,
         UPDATE_LICENSE,
         UPDATE_PROJECT
        } from "../graphql/mutations"
import { PROFILE_COMPLETION_STATUS } from "../graphql/queries"
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { setUserAction } from "../redux/actions/userActions"
import { profileProgressAction } from '../redux/actions/profileActions'
import { toast } from 'react-toastify'
import useAuthentication from './useAuthentication'
import 'react-toastify/dist/ReactToastify.css'
import { datadogLogs } from '@datadog/browser-logs';


const useProfile = () => {
  const dispatch: Dispatch<any> = useDispatch()
  const { getUserInformation } = useAuthentication()
  const [updateAccountInfo] = useMutation(UPDATE_ACCOUNT_INFO)
  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE)
  const [updateDegree] = useMutation(UPDATE_DEGREE)
  const [updateLicense] = useMutation(UPDATE_LICENSE)
  const [updateProject] = useMutation(UPDATE_PROJECT)
  const [, {refetch}] = useLazyQuery(PROFILE_COMPLETION_STATUS)
  const { signOut } = useAuthentication()

  const accountInfo = ({attributes, setError}: IUserData) => {
    datadogLogs.logger.info("Updating account info...");
    
    updateAccountInfo({
      variables: {
        attributes: attributes
      }
    }).then((response) => {
      datadogLogs.logger.info("Updated account info successfully.");
      toast.info("Account Information Successfully Updated")
      setError && setError('')
      const updatedUser = response.data.updateAccountInfo
      console.log(response)
      let user: IUser = {
        authorized: true,
        id: updatedUser.id,
        firstName: updatedUser.firstName,
        availabilityStatus: updatedUser.availabilityStatus,
        lastName: updatedUser.lastName,
        email: updatedUser.email,
        tcAcknowledgementPending: updatedUser.tcAcknowledgementPending,
        smAcknowledgementPending: updatedUser.smAcknowledgementPending
      }
      const credentials = JSON.parse(localStorage.getItem('credentials')!)
      if (credentials.uid !== user.email) {
        return signOut()
      }
      console.log(user)
      dispatch(setUserAction(user))
    })
    .catch((error) => {
      console.error('Error getting account information:', error.graphQLErrors.map((e: any)=> e.message))
      setError && setError(error.graphQLErrors[0]?.message)
    })
  }

  const updateUserProfileInfo = ({attributes, profileSectionName, setProfileData, setError}: IUserProfileData) => {
    let logDetails: Record<string,any> = {
        phoneNumber: attributes.phoneNumber?.replace(/[^\p{P}\p{S}\p{Z}]/giu, "x"),
        altPhoneNumber: attributes.altPhoneNumber?.replace(/[^\p{P}\p{S}\p{Z}]/giu, "x")
    };
    datadogLogs.logger.info(`Updating user profile ${profileSectionName}...`, logDetails);
    
    updateUserProfile({
      variables: {
        attributes: attributes
      }
    }).then((response) => {
      datadogLogs.logger.info("Updated user profile successfully.");
      toast.info("Profile Information Successfully Updated")
      setError && setError('')
      const updatedUserProfile = response.data.updateUserProfile
      getUserProfileStatus()
      getUserInformation(setProfileData)
      console.log(updatedUserProfile)
    })
    .catch((error) => {
      console.error('Error updating user profile:', error.graphQLErrors.map((e: any)=> e.message))
      setError && setError(error.graphQLErrors[0]?.message)
    })
  }

  const updateUserDegree = ({attributes, setError, setProfileData}: IDegreeData) => {
    datadogLogs.logger.info("Updating user profile education...");
    
    updateDegree({
      variables: {
        attributes: attributes
      }
    }).then((response) => {
      datadogLogs.logger.info("Updated user profile successfully.");
      toast.info("Profile Information Successfully Updated")
      setError && setError('')
      const updatedUserProfile = response.data
      getUserProfileStatus()
      getUserInformation(setProfileData)
      console.log('updateDegrees:', updatedUserProfile)
    })
    .catch((error) => {
      console.error('Error updating user degrees:', error.graphQLErrors.map((e: any)=> e.message))
      setError && setError(error.graphQLErrors[0]?.message)
    })
  }

  const updateUserLicense = ({attributes, setProfileData, setError}: ILicenseData) => {
    datadogLogs.logger.info("Updating user profile licensure...");
    
    updateLicense({
      variables: {
        attributes: attributes
      }
    }).then((response) => {
      datadogLogs.logger.info("Updated user profile successfully.");
      toast.info("Profile Information Successfully Updated")
      setError && setError('')
      const updatedUserProfile = response.data.updateUserProfile
      getUserProfileStatus()
      getUserInformation(setProfileData)
      console.log(updatedUserProfile)
    })
    .catch((error) => {
      console.error('Error updating user licenses:', error.graphQLErrors.map((e: any)=> e.message))
      setError && setError(error.graphQLErrors[0]?.message)
    })
  }

  const updateUserProject = ({attributes, setProfileData, setError}: IProjectData) => {
    datadogLogs.logger.info("Updating user profile projects...");
    
    updateProject({
      variables: {
        attributes: attributes
      }
    }).then((response) => {
      datadogLogs.logger.info("Updated user profile successfully.");
      toast.info("Profile Information Successfully Updated")
      setError && setError('')
      const updatedUserProfile = response.data.updateUserProfile
      getUserProfileStatus()
      getUserInformation(setProfileData)
      console.log(updatedUserProfile)
    })
    .catch((error) => {
      console.error('Error updating user projects:', error.graphQLErrors.map((e: any)=> e.message))
      setError && setError(error.graphQLErrors[0]?.message)
    })
  }

  const getUserProfileStatus = (setProfileStatusData?: React.Dispatch<React.SetStateAction<UserProfileStatus>>) => {
    datadogLogs.logger.info("Fetching user profile status...");
    
    refetch().then((response) => {
      datadogLogs.logger.info("User profile status fetched successfully");
      console.log(response.data)
      setProfileStatusData && setProfileStatusData(response.data.profileCompletionStatus)
      dispatch(profileProgressAction(response.data.profileCompletionStatus))
    }).catch((error) => {
      console.error('Error getting user profile status:', error.graphQLErrors.map((e: any)=> e.message))
    })
  }

  return { accountInfo, getUserProfileStatus, updateUserProfileInfo, updateUserDegree, updateUserLicense, updateUserProject }
}

export default useProfile
