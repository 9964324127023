import { useState } from 'react'
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TimelineDot from '@mui/lab/TimelineDot'
import { TimelineDotProps } from '@mui/lab/TimelineDot'
import classes from './Dashboard.module.scss'
import Icon from '../../../Icon';
import RBooleanToggle from '../../shared/ComponentLibrary/RBooleanToggle';
import useProfile from '../../../customHooks/useProfile';

const AlertTitles: Record<string, string> = {
  "notavailable": "Not Available Right Now",
  "available": "Available for Review Assignments",
}

const AlertMessage: Record<string, string> = {
  "notavailable": "You have indicated that you are not available for document review assignment opportunities at present. You will not be contacted with any new opportunities. To receive new opportunities, click the switch below:",
  "available": "We'll reach out to you with opportunities that match your background, skills, and qualifications. We are actively staffing numerous projects around the world, so you should be hearing from us soon.",
  "ineligible": "You will not be contacted with any document review assignment opportunities until you complete your profile and take the document review assessment. You cannot set your status to available until completion."
}

const Colors: Record<string, string> = {
  "notavailable": "#FC9C9C",
  "available": "#6BE3A2",
}

const IconNames: Record<string, string> = {
  "notavailable": 'highPriority2',
  "available": "checkmarkWhite",
}

function resolveStatus(status: boolean): string {
  if (status) {
    return "available"
  }
  return "notavailable"
}

function resolveStatusWithEligible(isEligible: boolean, isAvailable: boolean): string {
  if (!isEligible) {
    return "ineligible"
  }
  return resolveStatus(isAvailable)
}

type AvailabilityStatusProps = {
  availability: boolean
  tempUserDontKeep: {
    firstName: string,
    lastName: string,
    email: string
  }
  isEligible?: boolean
  updateAccount: ({ attributes, setError }: IUserData) => void
  setError: React.Dispatch<React.SetStateAction<string>>
}

const AvailabilityDashboardStatus = ({ availability, tempUserDontKeep, isEligible, updateAccount, setError }:AvailabilityStatusProps) => {
  
  const effectiveAvailability = (isEligible && availability) ?? false
  
  const updateAvailability = (availability : boolean) => {
    let updatedAccountData: IUserData = {
      attributes: {
        availabilityStatus: availability,
        firstName: tempUserDontKeep.firstName,
        lastName: tempUserDontKeep.lastName,
        email: tempUserDontKeep.email
      },
      setError: setError
    }
    updateAccount(updatedAccountData)
  }
  
  const CustomBox = styled(Box)({
    borderRadius: 10,
    fontSize: 14,
    fontWeight: 400,
    padding: '10px 15px',
    border: '1px solid',
    fontFamily: 'Open Sans',
    alignItems: 'center',
    flexGrow: 1
  })
  
  const CustomOuterBox = styled(CustomBox)({
    backgroundColor: '#01282F',
    borderColor: '#01282F',
    color: '#FFFFFF',
    padding: '15px 15px'
  })
  
  const CustomInnerBox = styled(CustomBox)({
    backgroundColor: '#000000',
    borderColor: '#000000',
    color: '#FFFFFF'
  })
  
  const CustomInnerBoxShadow = styled(CustomInnerBox)({
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
    color: '#393939',
    boxShadow: '0px 3px 6px #00000029'
  })
  
  const CustomBoxTitle = styled(Typography)({
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 0,
    marginTop: -2
  })
  
  const IsolatedTimelineDot = (props: TimelineDotProps) => (
    <TimelineDot 
      sx={{
        color: "white",
        backgroundColor: "#2CB6D1",
        width: 30,
        height: 30,
        alignItems: "center",
        justifyContent: "center",
        margin: 0}} 
      className={classes.timeline_dot} {...props}/>
  );

  return (
  <>
    <CustomOuterBox sx={{mb: 2}}>
      <Grid container spacing={1.5}>
        <Grid item xs="auto">
          <Icon name={IconNames[resolveStatus(effectiveAvailability)]} size='28' />
        </Grid>
        <Grid container item xs spacing={2}>
          <Grid item xs={12}>
            <CustomInnerBox>
              <CustomBoxTitle>Availability Status: { AlertTitles[resolveStatus(effectiveAvailability)] }</CustomBoxTitle>
              {AlertMessage[resolveStatusWithEligible(isEligible ?? false, availability ?? false)]}
            </CustomInnerBox>
          </Grid>
        </Grid>
      </Grid>
    </CustomOuterBox>
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <CustomInnerBoxShadow>
          <IsolatedTimelineDot>
            <span>3</span>
          </IsolatedTimelineDot>
          <Stack direction="row" spacing={1} sx={{alignItems: 'center', ml:3}}>
            <Icon name='viewFile' size='26' />
            <CustomBoxTitle>Set Your Status</CustomBoxTitle>
          </Stack>
          <Stack direction="row" sx={{justifyContent: 'center'}}>
            <RBooleanToggle 
              checked={effectiveAvailability} 
              disabled={!isEligible} 
              onChangeMethod={() => updateAvailability(!availability)}
              decals={{checkedIcon: <Icon name="checkmarkPlaceholder" size='31' />, uncheckedIcon: (isEligible ? <Icon name="minusSign" size='31' /> : <Icon name="lockPlaceholder" size='31' />), checkedText: 'Available', uncheckedText: 'Not Available'}}
              />
          </Stack>
        </CustomInnerBoxShadow>
      </Grid>
    </Grid>
  </>
  )
}

export default AvailabilityDashboardStatus
