import { gql } from '@apollo/client';


export const SET_USER = gql`
  mutation authenticateUser ($attributes: UserAuthenticateInput!) {
    authenticateUser(
      input: {
        attributes: $attributes 
      }
    ) {
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        availabilityStatus
        tcAcknowledgementPending
        smAcknowledgementPending
      }
      credentials {
        authorizationToken
        client
        expiry
        uid
      }
      confirmation
      confirmed
    }
  }
`

export const CREATE_USER = gql`
  mutation createUser ($attributes: UserInput!) {
    createUser(
      input: {
        attributes: $attributes
      }
    ) {
      firstName
      lastName
      email
    }
  }
`

export const USER_EMAIL_VERIFICATION = gql`
  mutation confirmUserEmail ($confirmationToken: String!) {
    confirmUserEmail(
      input: {
        confirmationToken: $confirmationToken
      }
    ) {
        status
        message
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword ($email: String!) {
    resetPassword(
      input: {
        email: $email
      }
    ) {
        status
        message
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation changePassword ($attributes: ResetPasswordInput!) {
    changePassword(
      input: {
        attributes: $attributes 
      }
    ) {
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        availabilityStatus
        tcAcknowledgementPending
        smAcknowledgementPending
      }
      credentials {
        authorizationToken
        client
        expiry
        uid
      }
    }
  }
`

export const START_PRACTICE_ASSESSMENT = gql`
  mutation practiceTest {
    createPracticeTest (input: {}) {
      assessment {
        id
        title
        questions {
          id
          question
        }
        secondsRemaining
        secondsElapsed
      }
      legalDocument {
        id
        title
        documentPages {
          imageUrl
          meta
        }
      }
      eligible {
        status
        message
      }
    }
  }
`

export const SUBMIT_PRACTICE_ASSESSMENT = gql`
  mutation submitResponse ($attributes: SubmitInput!) {
    submitPracticeTest (
      input: {
        attributes: $attributes
      }
    ) {
      assessment {
        id
        title
        questions {
          id
          question
        }
        secondsRemaining
        secondsElapsed
      }
      legalDocument {
        id
        title
        documentPages {
          imageUrl
          meta
        }
      }
      isCompleted
      feedback {
        legalDocument {
          id
          title
          documentPages {
            imageUrl
            meta
          }
        }
        responses {
          answer
          correct
          statement
        }
      }
    }
  }
`

export const START_ASSESSMENT = gql`
  mutation assessment {
    createAssessment (input: {}) {
      assessment {
        id
        title
        questions {
          id
          question
        }
        secondsRemaining
        secondsElapsed
        documentNumber
      }
      legalDocument {
        id
        title
        documentPages {
          imageUrl
          meta
        }
      }
      eligible {
        status
        message
      }
    }
  }
`

export const SUBMIT_ASSESSMENT = gql`
  mutation submitResponse ($attributes: SubmitInput!) {
    submitAssessment (
      input: {
        attributes: $attributes
      }
    ) {
      assessment {
        id
        title
        questions {
          id
          question
        }
        secondsRemaining
        secondsElapsed
      }
      legalDocument {
        id
        title
        documentPages {
          imageUrl
          meta
        }
      }
      isCompleted
    }
  }
`

export const CANCEL_ASSESSMENT = gql`
  mutation cancelAssessment {
    cancelAssessment (
      input: {}
    ) {
      status
      message
    }
  }
`

export const UPDATE_ACCOUNT_INFO = gql`
  mutation updateAccountInfo ($attributes: UpdateUserInput!) {
    updateAccountInfo(
      input: {
        attributes: $attributes 
      }
    ) {
      id
      firstName
      availabilityStatus
      lastName
      email
      tcAcknowledgementPending
      smAcknowledgementPending
    }
  }
`

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile ($attributes: UserUpdateInput!) {
    updateUserProfile(
      input: {
        attributes: $attributes
      }
    ) {
      firstName
      lastName
      email
      phoneNumber
      altPhoneNumber
      primaryCountryCode
      alternateCountryCode
      pcPronouns
      address1
      address2
      city
      state
      zipcode
      otherLanguage
      usCitizen
      informalReferral
      attorney
      reviewLanguages
      resumeName
      training
      foreignLicense
      biography

      country {
        name
        value
      }

      region {
        name
        value
      }

      languages {
        name
        value
      }

      degrees {
        id
        degreeName
        university
        major
        honors
        dateCompleted
      }

      licenses {
        id
        state
        yearPassed
        verified
        attorneyId
      }

      projects {
        id
        durationMonths
        responsibilities
        practiceArea {
          name
          value
        }
        industry {
          name
          value
        }
        softwareProduct {
          name
          value
        }
        reviewMethodology {
          name
          value
        }
        languages {
          name
          value
        }
      }
    }
  }
`

export const UPDATE_DEGREE = gql`
  mutation updateDegree ($attributes: UpdateDegreeInputType!) {
    updateDegree(
      input: {
        attributes: $attributes
      }
    ) {
        message
      }
  }
`

export const UPDATE_LICENSE = gql`
  mutation updateLicense ($attributes: UpdateLicenseInputType!) {
    updateLicense(
      input: {
        attributes: $attributes
      }
    ) {
        message
      }
  }
`

export const UPDATE_PROJECT = gql`
  mutation updateProject ($attributes: UpdateProjectInputType!) {
    updateProject(
      input: {
        attributes: $attributes
      }
    ) {
        message
      }
  }
`

export const SUBMIT_COMMENT = gql`
  mutation commentAssessment ($comment: String!) {
    commentAssessment(
      input: {
        comment: $comment
      }
    ) {
        status
        message
      }
  }
`

export const TC_ACKNOWLEDGEMENT = gql`
  mutation tcAcknowledgement ($acknowledgement: Boolean!) {
    tcAcknowledgement(
      input: {
        acknowledgement: $acknowledgement
      }
    ) {
      id
      firstName
      lastName
      email
      availabilityStatus
      tcAcknowledgementPending
      smAcknowledgementPending
    }
  }
`

export const SM_ACKNOWLEDGEMENT = gql`
  mutation smAcknowledgement ($acknowledgement: Boolean!) {
    smAcknowledgement(
      input: {
        acknowledgement: $acknowledgement
      }
    ) {
      id
      firstName
      lastName
      email
      availabilityStatus
      tcAcknowledgementPending
      smAcknowledgementPending
    }
  }
`

export const INBOX_ACKNOWLEDGEMENT = gql`
  mutation inboxAcknowledgement ($acknowledgement: Boolean!, $userId: ID!, $systemMessageId: ID!) {
    inboxAcknowledgement(
      input: {
        acknowledgement: $acknowledgement
        userId: $userId
        systemMessageId: $systemMessageId
        
      }
    ) {
      content
      title
      priority
      id
      read
      publishedAt
    }
  }
`
