import { useEffect, useState } from "react"
import PageHeading from "../../shared/PageHeading"
import TabPanel from "../../shared/ComponentLibrary/TabPanel"
import InboxTabs from "./InboxTabs"
import { useSelector } from "react-redux"
import InboxGrid from "./InboxGrid"
import BreadcrumbsPath from "../../shared/BreadcrumbsPath"

const Inbox = () => {
  const [activeTab, setActiveTab] = useState<string>('unread')
  const inboxMessages: InboxMessages = useSelector((state: InboxMessagesState) => state.inboxMessages.inboxMessages)
  useEffect(() => {
    // Re-render when inboxMessages changes
  }, [inboxMessages])
  return (
    <div>
      <BreadcrumbsPath />
      <PageHeading
        icon='inbox'
        title='Inbox'
        divider
      />
      <InboxTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <div>
        <TabPanel activeTab={activeTab} index={'all'}>
          <InboxGrid inboxMessages={inboxMessages}/>
        </TabPanel>
        <TabPanel activeTab={activeTab} index={'read'}>
          <InboxGrid inboxMessages={inboxMessages.filter((msg) => msg.read == true)}/>
        </TabPanel>
        <TabPanel activeTab={activeTab} index={'unread'}>
          <InboxGrid inboxMessages={inboxMessages.filter((msg) => msg.read == false)}/>
        </TabPanel>
      </div>
    </div>
  )
}

export default Inbox
