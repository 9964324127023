export type RouteType = {
  name: string;
  path: string;
};

export const Routes: Record<string, RouteType> = Object.freeze({
  dashboard: { name: "Dashboard", path: "/" },
  account: { name: "Account", path: "/account" },
  profile: { name: "Profile", path: "/profile" },
  assessment: { name: "Assessment", path: "/assessment" },
  inbox: { name: "Inbox", path: "/inbox" },
});

export default Routes;
