import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import classes from './Assessment.module.scss'
import useAssessment from '../../../customHooks/useAssessment'
import { HtmlSafeElement } from '../../shared/HtmlSafeElement'
import Icon from '../../../Icon'
import Button from '../../shared/ComponentLibrary/RButton'
import PageHeading from '../../shared/PageHeading'
import { useSelector } from 'react-redux'
import BreadcrumbsPath from '../../shared/BreadcrumbsPath'


const AssessmentIntroduction = () => {
  const navigate = useNavigate()

  const profileStatus: UserProfileStatus = useSelector((state: ProfileState) => state.profile)
  const [profileStatusData, setProfileStatusData] = useState<UserProfileStatus>(profileStatus)

  return (
    <div>
      <BreadcrumbsPath />

      <div className={classes.assessment_container}>
        <PageHeading
          icon='testResults'
          title='Assessment'
          divider
        />

        <div className={classes.grey_text}>
          <p>
            The final step for document review assignment eligibility is the assessment."
          </p>
          <p className={classes.assessment_done}>
            {profileStatusData.realAssessment && "You have already taken the assessment."}
          </p>
          <p>
            To prepare for the test, {" "}
            <Link to='/assessment/instructions'>
              {"read the instructions"}
            </Link>
            {" or take the "}
            <Link to='/assessment/practice'>
              practice test
            </Link>
            .
          </p>
        </div>

        <div className={classes.profile_step_container}>
          <div className={classes.step_container}>
            <Link to='/assessment/begin' >
              <div className={classes.step}>
                <div className={classes.step_content}>
                  <Icon name='page100Copy' size='28' />
                  <div className={classes.step_title}>Document Review Assessment</div>
                </div>
                <Button innerText='Start Test' variant='outlined' />
              </div>
            </Link>
            {
              profileStatusData.realAssessment ?
                <Icon name='checkmarkGreen' size='28' /> :
                <Icon name='highPriorityFilled' size='28' />
            }
          </div>
        </div>

      </div>
    </div>
  )
}

export default AssessmentIntroduction
