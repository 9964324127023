import React, { useEffect, useState } from 'react'
import classes from '../Home.module.scss'
import Button from '../../shared/ComponentLibrary/RButton'
import { useSelector } from 'react-redux'
import useInboxMessages from '../../../customHooks/useInbox'

type HighPriorityMessageProps = {
  message: InboxMessage;
};

const HighPriorityMessage = ({ message }: HighPriorityMessageProps) => {
  const { createInboxAcknowledgement } = useInboxMessages()
  var HTMLContent: any;
  const user: IUser = useSelector((state: UserState) => state.user)
  const [isClicked, setIsClicked] = useState(false);
  const publishedDate = new Date(message?.publishedAt || '');

  useEffect(() => {
    // re-render
  }, [user])

  if (message) {
    HTMLContent = { __html: message?.content }
  }

  const updateProfile: any = () => {
    if (!isClicked) {
      setIsClicked(true);
      createInboxAcknowledgement(true, Number(user.id), Number(message.id), 'high');
    }
  }

  const formattedDate = publishedDate.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });

  const formattedTime = publishedDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return (
    <div className={classes.sm_container}>
      <div className={classes.flex__row}>
        <span className={classes.msg__title}>{message?.title}</span>
        <span className={classes.font__semibold}>{formattedDate}, {formattedTime}</span>
      </div>
      <div dangerouslySetInnerHTML={HTMLContent} className={classes.rich_text_html} />
      <div className={classes.form}>
        <div className='d-flex justify-content-center mt-4'>
          <Button size='large' innerText='Acknowledge' onClickMethod={updateProfile} disabled={isClicked} />
        </div>
      </div>
    </div>
  )
}

export default HighPriorityMessage
