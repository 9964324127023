import classes from './Sidebar.module.scss'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from 'react-router-dom'
import Icon from '../../../Icon'
import useAuthentication from '../../../customHooks/useAuthentication'
import { useEffect, useState } from 'react'
import Tab from './Tab'
import { updateActiveTab, setDirtyForm } from '../../../redux/actions/profileActions'
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import WarningModal from './WarningModal'


type RoutesType = {
  [key: string]: RouteType
}

const Sidebar = () => {
  let location = useLocation()
  let navigate = useNavigate()
  const dispatch: Dispatch<any> = useDispatch()
  const [selectedTab, setSelectedTab] = useState<string>(location.pathname)
  const profileStatus: UserProfileStatus = useSelector((state: ProfileState) => state.profile)
  const [activeSubMenu, setActiveSubMenu] = useState(profileStatus.activeTab)
  const { signOut } = useAuthentication()
  const [showModal, setShowModal] = useState(false)
  const [nextTab, setNextTab] = useState('')
  const [nextSubMenu, setNextSubMenu] = useState('')

  const Routes: RoutesType = Object.freeze({
    'dashboard': { name: 'Dashboard', path: '/', icon: 'homePage' },
    'inbox': { name: 'Inbox', path: '/inbox', icon: 'inbox' },
    'profile': {
      name: 'Profile',
      path: '/profile',
      icon: 'genderNeutralUser',
      subRoutes: [
        { name: 'Basic Information', isCompleted: profileStatus.basicInformation, path: 'information' },
        { name: 'Education', isCompleted: profileStatus.education, path: 'education' },
        { name: 'About Me', isCompleted: profileStatus.biography, path: 'about' },
        { name: 'Resume', isCompleted: profileStatus.resume, path: 'resume' },
        { name: 'Licensure', isCompleted: profileStatus.licensure, path: 'licensure' },
        { name: 'Review Experience', isCompleted: profileStatus.documentReviewExperience, path: 'experience' }
      ],
    },
    'assessment': { name: 'Assessment', path: '/assessment', icon: 'testResults' },
    'account': { name: 'Account', path: '/account', icon: 'gear' },
  })

  useEffect(() => {
    setSelectedTab(location.pathname)
    location.state?.from && setActiveSubMenu(location.state?.from)
  }, [location.pathname])

  useEffect(() => { dispatch(updateActiveTab({...profileStatus, activeTab: activeSubMenu})) }, [selectedTab, activeSubMenu])
  useEffect(() => setActiveSubMenu(profileStatus.activeTab), [profileStatus.activeTab])


  const activateParentHandler = (event: any, tab: string) => {
    if (profileStatus.dirtyForm) {
      setNextTab(tab)
      event.preventDefault()
      setShowModal(true)
    }
    else {
      setSelectedTab(tab)
      setActiveSubMenu('information')
    }
  }

  const confirmTabChange = () => {
    dispatch(setDirtyForm({...profileStatus, dirtyForm: false}))

    if (nextSubMenu !== '') {
      setActiveSubMenu(nextSubMenu)
      setNextSubMenu('')
    }
    else if (nextTab !== '')  {
      navigate(nextTab)
      setSelectedTab(nextTab)
      setActiveSubMenu('information')
    }
    setShowModal(false)
  }

  const activateSubMenuHandler = (event: any, subMenu: string) => {
    if (profileStatus.dirtyForm) {
      event.preventDefault()
      setNextSubMenu(subMenu)
      setShowModal(true)
    }
    else {
      setActiveSubMenu(subMenu)
    }
  }

  return (
    <div className={classes.sidebar}>
      <WarningModal
        show={showModal}
        setShow={setShowModal}
        onConfirm={() => confirmTabChange()}
      />
      <div>
        <div className={classes.logo_container} >
          <img
            src={'/images/HaystackID Gray - Logo.png'}
            className={classes.logo}
            alt="review-right-logo" />
        </div>

        <div className={classes.navigations}>
          <List>
            {Object.keys(Routes).map((key) => (
              <ListItem key={key} disablePadding >
                <ListItemButton classes={{root: classes.list_item}} disableTouchRipple >
                  <Tab
                    route={Routes[key]}
                    isActiveTab={Routes[key].path === selectedTab }
                    activeSubMenu={activeSubMenu}
                    setActiveSubMenu={(event: any, subMenu: string) => {
                      activateSubMenuHandler(event, subMenu)
                    }}
                    activateParentTab={(event: any) => {
                      activateParentHandler(event, Routes[key].path)
                    }}
                    arrowColor='#025A66'
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </div>
      </div>

      <div className={classes.signout_button}>
        <button className={`btn ${classes.button}`} onClick={signOut}>
          <Icon name='logoutRoundedLeft' size='18' />
          <span>Sign Out</span>
        </button>
      </div>

      <div className={classes.version_number}>
        <span>{`v${process.env.REACT_APP_VERSION_NO}`}</span>
      </div>
    </div>
  )
}

export default Sidebar
