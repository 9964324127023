import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classes from './Assessment.module.scss'
import useAssessment from '../../../customHooks/useAssessment'
import { HtmlSafeElement } from '../../shared/HtmlSafeElement'
import Icon from '../../../Icon'
import Button from '../../shared/ComponentLibrary/RButton'
import PageHeading from '../../shared/PageHeading'
import BreadcrumbsPath from '../../shared/BreadcrumbsPath'


const AssessmentInstructions = () => {
  const navigate = useNavigate()

  const { getIntroPage } = useAssessment()
  const [introPageData, setIntroPageData] = useState<IAssessmentIntroduction>()
  const [pageNumber, setPageNumber] = useState<number>(1)

  useEffect(() => {
    getIntroPage(pageNumber, setIntroPageData)
  }, [pageNumber])

  const nextPage = () => setPageNumber((current) => current + 1)
  const previousPage = () => setPageNumber((current) => current - 1)

  return(
    <div className={classes.assessment_container}>
      <div className={classes.header}>
        <PageHeading title='Assessment Instructions' icon='testResults' />
        <BreadcrumbsPath />
      </div>
      <div className={classes.page_count}>Page {pageNumber} / 11</div>
      {
        introPageData &&
        <div className={classes.introduction}>
          <div className={classes.main_heading}>
            <HtmlSafeElement element="h4" content={introPageData.mainHeading}  />
            <HtmlSafeElement element="p" content={introPageData.mainHeadingContent}  />
          </div>

          {
            introPageData.subHeadingContentList &&
            introPageData.subHeadingContentList.subHeadingContent.map((subHeading, index) => {
              return(
                <div key={index}>
                  <HtmlSafeElement element="h4" content={subHeading.heading}  />
                  <HtmlSafeElement element="p" content={subHeading.content}  />
                </div>
              )
            })
          }

          {
            introPageData.otherInformation &&
            <div>
              <div>For further information, see</div>
              {
                introPageData.otherInformation.sourceLinks.map((sourceLink, index) => {
                  return(
                    <small>
                      <HtmlSafeElement element="div" key={index} content={sourceLink}  />
                    </small>
                  )
                })
              }
            </div>
          }

          {
            introPageData.questionsList &&
            introPageData.questionsList.questions.map((question, index) => {
              return(
                <div key={index}>
                  <b>{index + 1}. </b>
                  <HtmlSafeElement element="span" content={question}  />
                  <br />
                  <br />
                </div>
              )
            })
          }

          <div>
            <HtmlSafeElement element='p' content={introPageData.source}/>
          </div>
        </div>
      }
      <div className={classes.navigation_section}>
        <hr className={classes.divider}/>
        <div className={classes.navigation_buttons}>
          <div className={classes.back}>
            <Button onClickMethod={previousPage} hidden={pageNumber <= 1}>
              <Icon name='longArrowLeft' color='#FFF' />
              Back
            </Button>
          </div>
          <div className={classes.next}>
            <Button onClickMethod={nextPage} hidden={pageNumber >= 11}>
              Next
              <Icon name='longArrowLeft' color='#FFF' transform />
            </Button>
            <Button
              innerText='Take the Assessment'
              size='small'
              onClickMethod={() => navigate('/assessment/begin')}
              hidden={pageNumber < 11}
            />
            <Button
              innerText='Take the Practice Test'
              size='small'
              variant='outlined'
              onClickMethod={() => navigate('/assessment/practice')}
              hidden={pageNumber < 11}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssessmentInstructions
